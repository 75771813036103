@import './variables.scss';

.Title {
  width: auto !important;
}

.Subtitle {
  margin-top: 24px !important;
  width: auto;
  max-width: 384px;
}

.Image {
  padding-top: 56px;
}
