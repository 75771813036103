@import '../../styles/variables.scss';

.InputFilds {
  display: flex;
  justify-content: center;
  align-items: center;

  width: 100%;
  max-width: 420px;

  margin: auto;
  margin-top: 30px;
  max-height: 150px;
  background-color: #fff;

  input:focus {
    border-color: $input-border-focus;
    color: $input-text;
    outline: 0;
    box-shadow: 0 0 0 0.2rem $input-shadow;
  }

  input {
    width: 90%;
    max-width: 400px;
    height: calc(2em + 0.75rem + 2px);
    font-size: 1rem;
    color: $input-text;

    padding: 0.375rem 0.75rem;
    font-weight: 400;
    line-height: 1.5;
    background-clip: padding-box;

    border: 1px solid  $input-border;
    border-right: none;
    border-radius: 0 0 0 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  }

  span {
    width: 10%;
    font-size: 1rem;
    border: 1px solid  $input-border;
    border-left: none;
    padding: 0 20px;
    height: calc(2em + 0.75rem + 2px);
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.ErrorMessage {
  text-align: start;
  max-width: 420px;
  color: $error-message;
  padding-top: 5px;
}
