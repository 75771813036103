$background: #EEEDF1;
$main-font: OpenSans, Arial, sans-serif;
$main-font-bold: OpenSans-Bold, Arial, sans-serif;
$main-text-color: #535161;

$strength-background: #ddd;

$input-text: #495057;
$input-border-focus: #e6007e;
$input-shadow: #e6007e3d;
$input-border: #ced4da;

$error-message: #e02020;

$button-active: #e3047d;
$button-disabled: #a4a3af;