@import '../../styles/variables.scss';

.MainDiv {
  position: relative;
  width: 100%;
  max-width: 425px;
  margin: 0 auto;
  text-align: left;
}

.StrengthMeter {
  background: transparent;
  height: inherit;
  position: absolute;
  transition: width 0.5s ease-in-out, background 0.25s;
}

.StrengthDiv {
  position: relative;
  height: 2px;
  background: $strength-background;
  margin: 7px 0;
  border-radius: 2px;
  overflow: hidden;

  &:before,
  &:after {
    content: '';
    height: inherit;
    background: transparent;
    display: block;
    border-color: #fff;
    border-style: solid;
    border-width: 0 5px 0;
    position: absolute;
    width: calc(20%);
    z-index: 2;
  }
  &:before {
    left: calc(20%);
  }
  &:after {
    right: calc(20%);
  }
}

.TextDiv {
  font-size: 0.875rem;

  p {
    font-weight: 600;
  }

  ul {
    padding-left: 20px !important;
  }
}

.StrengthText {
  margin-top: 0;
  font-size: 13px;
}
