@import './variables.scss';

.Badges {
  font-family: $main-font;
  max-width: 400px;
  margin: auto;
  margin-top: 16px;
  text-align: left;
  color: $main-text-color;

  span {
    padding: 0;
    font-weight: 600;
    font-size: 12px;
    font-family: $main-font-bold;
    margin-bottom: 8px;
  }
  ul {
    margin-top: 8px;
    padding: 0;
    padding-left: 14px;

    li {
      font-weight: normal;
      font-size: 12px;
    }
  }
}

.SpinnerContainer{
  height: 100vh;
  display: flex;
  align-items: center;
}
