@import '../../styles/variables.scss';

.BoxCustomContainer {
  box-sizing: border-box;
  color: $main-text-color;
  width: 100%;
  height: 100%;
  max-width: 730px;
  min-height: 636px;
  border-radius: 4px;
  background-color: #fff;
  padding: 30px;
  margin: 5% auto auto;
  box-shadow: 1px 1px 1px 1px rgb(0 0 0 / 10%);
  
  @media screen and (max-width: 600px) {
    width: 100vw;
    min-height: 100vh;
    height: auto;
    margin: 0;
    border-radius: 0;
  }

  h1 {
    width: 248px;
    font-family: $main-font-bold;
    font-size: 36px;
    font-weight: bold;
    line-height: 120%;
    margin: 10px auto 24px;
  }

  p {
    font-size: 1em;
    letter-spacing: -0.4px;
    line-height: 21px;
    font-family: $main-font;
    margin: auto;
  }

  .TitleContainer{
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    margin-top: 5%;
    margin-bottom: auto;
  }
}
