@import '../../styles/variables.scss';

.Button {
  width: 100%;
  max-width: 400px;
  background-color: $button-active;
  border-color: $button-active;
  color: #fff;
  margin: auto;
  margin-top: 36px;
  font-family: $main-font-bold;
  cursor: pointer;
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem;
  border: 1px solid transparent;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
  box-shadow 0.15s ease-in-out;

  &:disabled {
    background-color: $button-disabled !important;
    border-color: $button-disabled !important;
    cursor: not-allowed;
  }
}
